/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AcademyUserBaseSchema */
export interface AcademyUserBaseSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Newsletter Consent */
  newsletter_consent: boolean;
  /** Email */
  email: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Field Values */
  field_values: UserFieldValueSchema[];
  /** Tag Uuids */
  tag_uuids: string[];
  registration_status: RegistrationStatusEnum;
  /** Parent Uuid */
  parent_uuid: string | null;
}

/** AcademyUserListSchema */
export interface AcademyUserListSchema {
  /** Objects */
  objects: AcademyUserBaseSchema[];
}

/** AcademyUserSchema */
export interface AcademyUserSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Newsletter Consent */
  newsletter_consent: boolean;
  /** Email */
  email: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Field Values */
  field_values: UserFieldValueSchema[];
  /** Tag Uuids */
  tag_uuids: string[];
  registration_status: RegistrationStatusEnum;
  /** Parent Uuid */
  parent_uuid: string | null;
  /** Children */
  children: AcademyUserBaseSchema[];
  parent: AcademyUserBaseSchema | null;
}

/** AnswerSchema */
export interface AnswerSchema {
  /** Field Uuid */
  field_uuid: string;
  /** String Value */
  string_value?: string | null;
  /** Integer Value */
  integer_value?: number | null;
  /** Boolean Value */
  boolean_value?: boolean | null;
  /** Datetime Value */
  datetime_value?: string | null;
  /** Float Value */
  float_value?: number | null;
}

/** Body__create_file_core_files_post */
export interface BodyCreateFileCoreFilesPost {
  /**
   * File
   * @format binary
   */
  file: File;
  /**
   * Context Uuid
   * @default ""
   */
  context_uuid?: string;
  /**
   * Public
   * @default false
   */
  public?: boolean;
}

/** Body__token_auth_login_post */
export interface BodyTokenAuthLoginPost {
  /** Grant Type */
  grant_type?: string | null;
  /** Username */
  username: string;
  /** Password */
  password: string;
  /**
   * Scope
   * @default ""
   */
  scope?: string;
  /** Client Id */
  client_id?: string | null;
  /** Client Secret */
  client_secret?: string | null;
}

/** CompleteRegistrationRequest */
export interface CompleteRegistrationRequest {
  /** Token */
  token: string;
  /** Password */
  password: string;
}

/** CompleteRegistrationResponse */
export interface CompleteRegistrationResponse {
  /** Otp */
  otp: string;
}

/** CourseBaseSchema */
export interface CourseBaseSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Lesson Count */
  lesson_count: number;
  /**
   * Lesson Completed Count
   * @default 0
   */
  lesson_completed_count?: number;
}

/** CourseDetailSchema */
export interface CourseDetailSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Lesson Count */
  lesson_count: number;
  /**
   * Lesson Completed Count
   * @default 0
   */
  lesson_completed_count?: number;
  /** Lessons */
  lessons: LessonBaseSchema[];
}

/** CourseListResponse */
export interface CourseListResponse {
  /** Objects */
  objects: CourseBaseSchema[];
}

/** CreateAcademyUserRequest */
export interface CreateAcademyUserRequest {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Email */
  email: string;
  /** Field Values */
  field_values: UpdateUserFieldValueRequest[];
  /** Tag Uuids */
  tag_uuids: string[];
  /** Parent Uuid */
  parent_uuid: string | null;
}

/** CreateAnswerSchema */
export interface CreateAnswerSchema {
  /** Field Uuid */
  field_uuid: string;
  /** Value */
  value: string;
}

/** CreateCourseRequest */
export interface CreateCourseRequest {
  /** Title */
  title: string;
  /** Description */
  description: string;
}

/** CreateFormResponseRequest */
export interface CreateFormResponseRequest {
  /** Answers */
  answers: CreateAnswerSchema[];
}

/** CreateGroupRequest */
export interface CreateGroupRequest {
  /** Name */
  name: string;
  /** Description */
  description: string;
}

/** CreateLessonRequest */
export interface CreateLessonRequest {
  /** Title */
  title: string;
  /** Content */
  content: string;
  /** Order */
  order: number;
  /** Course Uuid */
  course_uuid: string;
}

/** CreateMeetingRequest */
export interface CreateMeetingRequest {
  /** Title */
  title: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Description */
  description: string;
  /** Participant Uuids */
  participant_uuids: string[];
  /** Group Uuid */
  group_uuid?: string | null;
  /** @default "jitsi" */
  meeting_type?: MeetingTypeEnum;
  /** Custom Url */
  custom_url?: string | null;
  /** Host Uuid */
  host_uuid?: string | null;
}

/** CreateProductRequest */
export interface CreateProductRequest {
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Price Cents */
  price_cents: number;
  /** Currency */
  currency: string;
  /** Subscription Duration Value */
  subscription_duration_value: number;
  subscription_duration_unit: SubscriptionDurationUnit;
  /** Active */
  active: boolean;
  /** Group Uuids */
  group_uuids: string[];
}

/** CreateSubscribtionRequest */
export interface CreateSubscribtionRequest {
  /** Product Uuid */
  product_uuid: string;
  /** User Uuid */
  user_uuid: string;
  status: SubscriptionStatusEnum;
  /**
   * Valid Until
   * @format date-time
   */
  valid_until: string;
  /**
   * Started On
   * @format date-time
   */
  started_on: string;
  /** Joined Group Uuid */
  joined_group_uuid: string | null;
}

/** CreateUserFieldRequest */
export interface CreateUserFieldRequest {
  field_type: UserFieldTypeEnum;
  /** Label */
  label: string;
  /** Display Order */
  display_order: number;
  /** Display In List */
  display_in_list: boolean;
}

/** CreateUserRequest */
export interface CreateUserRequest {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Email */
  email: string;
  /** Field Values */
  field_values: UpdateUserFieldValueRequest[];
  /** Tag Uuids */
  tag_uuids: string[];
}

/** CreateUserTagRequest */
export interface CreateUserTagRequest {
  /** Label */
  label: string;
  /** Color */
  color: string;
}

/** EmailBaseSchema */
export interface EmailBaseSchema {
  /** Uuid */
  uuid: string;
  /** Recipients */
  recipients: UserBaseSchema[];
  /** Subject */
  subject: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
}

/** EmailCreateRequest */
export interface EmailCreateRequest {
  /** Subject */
  subject: string;
  /** Recipient Uuids */
  recipient_uuids: string[];
  /**
   * Content Html
   * @default ""
   */
  content_html?: string;
  /**
   * Content Plain
   * @default ""
   */
  content_plain?: string;
  /**
   * Content Raw
   * @default ""
   */
  content_raw?: string;
  /**
   * Send
   * @default false
   */
  send?: boolean;
}

/** EmailListResponse */
export interface EmailListResponse {
  /** Objects */
  objects: EmailBaseSchema[];
}

/** EmailRenderBaseSchema */
export interface EmailRenderBaseSchema {
  /** Uuid */
  uuid: string;
  status: EmailStatusEnum;
  /** Recipient Uuid */
  recipient_uuid: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
}

/** EmailSchema */
export interface EmailSchema {
  /** Uuid */
  uuid: string;
  /** Recipients */
  recipients: UserBaseSchema[];
  /** Subject */
  subject: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Content Html */
  content_html: string;
  /** Content Plain */
  content_plain: string;
  /** Content Raw */
  content_raw: string;
  /** Renders */
  renders: EmailRenderBaseSchema[];
}

/** EmailStatusEnum */
export enum EmailStatusEnum {
  Sending = "sending",
  Sent = "sent",
  Draft = "draft",
}

/** EmailTemplateBaseSchema */
export interface EmailTemplateBaseSchema {
  /** Uuid */
  uuid: string;
  /** Subject */
  subject: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Purpose */
  purpose: string;
  /** Language */
  language: string;
  /** Default */
  default: boolean;
}

/** EmailTemplateCreateRequest */
export interface EmailTemplateCreateRequest {
  /** Subject */
  subject: string;
  /** Purpose */
  purpose: string;
  /** Language */
  language: string;
  /**
   * Content Html
   * @default ""
   */
  content_html?: string;
  /**
   * Content Plain
   * @default ""
   */
  content_plain?: string;
  /**
   * Content Raw
   * @default ""
   */
  content_raw?: string;
  /**
   * Default
   * @default false
   */
  default?: boolean;
}

/** EmailTemplateListResponse */
export interface EmailTemplateListResponse {
  /** Objects */
  objects: EmailTemplateBaseSchema[];
}

/** EmailTemplateSchema */
export interface EmailTemplateSchema {
  /** Uuid */
  uuid: string;
  /** Subject */
  subject: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Purpose */
  purpose: string;
  /** Language */
  language: string;
  /** Default */
  default: boolean;
  /** Content Html */
  content_html: string;
  /** Content Plain */
  content_plain: string;
  /** Content Raw */
  content_raw: string;
}

/** EmailTemplateUpdateRequest */
export interface EmailTemplateUpdateRequest {
  /** Subject */
  subject?: string | null;
  /** Content Html */
  content_html?: string | null;
  /** Content Plain */
  content_plain?: string | null;
  /** Content Raw */
  content_raw?: string | null;
  /** Default */
  default?: boolean | null;
  /** Language */
  language?: string | null;
  /** Purpose */
  purpose?: string | null;
}

/** EmailUpdateRequest */
export interface EmailUpdateRequest {
  /** Recipient Uuids */
  recipient_uuids?: string[] | null;
  /** Subject */
  subject?: string | null;
  /** Content Html */
  content_html?: string | null;
  /** Content Plain */
  content_plain?: string | null;
  /** Content Raw */
  content_raw?: string | null;
  /** Send */
  send?: boolean | null;
}

/** FileSchema */
export interface FileSchema {
  /** Uuid */
  uuid: string;
  /** File Name */
  file_name: string;
  /** Public */
  public: boolean;
  /** Download Url */
  download_url?: string | null;
  /** Permanent Url */
  permanent_url?: string | null;
  /** Deleted On */
  deleted_on?: string | null;
  /** Created At */
  created_at: string | null;
  /** Updated At */
  updated_at: string | null;
  /** Context Uuid */
  context_uuid: string;
  /** Size */
  size: number;
  /** Mimetype */
  mimetype: string | null;
}

/** FileSchemaListResponse */
export interface FileSchemaListResponse {
  /** Objects */
  objects: FileSchema[];
}

/** FormBaseSchema */
export interface FormBaseSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** One Response Per User */
  one_response_per_user: boolean;
}

/** FormCreateRequest */
export interface FormCreateRequest {
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Confirmation Message */
  confirmation_message: string;
  /**
   * One Response Per User
   * @default false
   */
  one_response_per_user?: boolean;
  /**
   * Error Message
   * @default ""
   */
  error_message?: string;
}

/** FormFieldCreateRequest */
export interface FormFieldCreateRequest {
  field_type: FormFieldTypeEnum;
  /** Form Uuid */
  form_uuid: string;
  /** Label */
  label: string;
  /** Placeholder */
  placeholder: string;
  /** Required */
  required: boolean;
  /** Order */
  order: number;
  /** Display Options */
  display_options: string;
}

/** FormFieldSchema */
export interface FormFieldSchema {
  /** Uuid */
  uuid: string;
  /** Form Uuid */
  form_uuid: string;
  field_type: FormFieldTypeEnum;
  /** Label */
  label: string;
  /** Placeholder */
  placeholder: string;
  /** Required */
  required: boolean;
  /** Order */
  order: number;
  /** Display Options */
  display_options: string;
}

/** FormFieldTypeEnum */
export enum FormFieldTypeEnum {
  String = "string",
  Integer = "integer",
  Boolean = "boolean",
  Datetime = "datetime",
  Name = "name",
  Email = "email",
  EmailConsent = "email_consent",
  SingleChoice = "single_choice",
  User = "user",
}

/** FormFieldUpdateRequest */
export interface FormFieldUpdateRequest {
  /** Label */
  label?: string | null;
  /** Placeholder */
  placeholder?: string | null;
  /** Required */
  required?: boolean | null;
  /** Order */
  order?: number | null;
  /** Display Options */
  display_options?: string | null;
}

/** FormListResponse */
export interface FormListResponse {
  /** Objects */
  objects: FormBaseSchema[];
}

/** FormPublicSchema */
export interface FormPublicSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** One Response Per User */
  one_response_per_user: boolean;
  /** Fields */
  fields: FormFieldSchema[];
  /** Description */
  description: string;
  /** Confirmation Message */
  confirmation_message: string;
  /** Error Message */
  error_message: string;
}

/** FormResponseListSchema */
export interface FormResponseListSchema {
  /** Objects */
  objects: FormResponseSchema[];
}

/** FormResponseSchema */
export interface FormResponseSchema {
  /** Uuid */
  uuid: string;
  /** Form Uuid */
  form_uuid: string;
  /** Answers */
  answers: AnswerSchema[];
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** FormSchema */
export interface FormSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** One Response Per User */
  one_response_per_user: boolean;
  /** Fields */
  fields: FormFieldSchema[];
  /** Form Tokens */
  form_tokens: FormTokenResponse[];
  /** Description */
  description: string;
  /** Tag */
  tag: string;
  /** Confirmation Message */
  confirmation_message: string;
  email_template: EmailTemplateBaseSchema | null;
  /** Error Message */
  error_message: string;
}

/** FormTokenResponse */
export interface FormTokenResponse {
  /** Token */
  token: string;
  /** Single Use */
  single_use: boolean;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** FormUpdateField */
export interface FormUpdateField {
  field_type: FormFieldTypeEnum;
  /** Label */
  label: string;
  /** Placeholder */
  placeholder: string;
  /** Required */
  required: boolean;
  /** Order */
  order: number;
  /** Display Options */
  display_options: string;
  /** Uuid */
  uuid: string;
}

/** FormUpdateRequest */
export interface FormUpdateRequest {
  /** Title */
  title?: string | null;
  /** Description */
  description?: string | null;
  /** Confirmation Message */
  confirmation_message?: string | null;
  /** Fields */
  fields?: FormUpdateField[] | null;
  /** Email Template Uuid */
  email_template_uuid?: string | null;
  /** Tag */
  tag?: string | null;
  /** One Response Per User */
  one_response_per_user?: boolean | null;
  /** Error Message */
  error_message?: string | null;
}

/** GroupBaseSchema */
export interface GroupBaseSchema {
  /** Uuid */
  uuid: string;
  /** Name */
  name: string;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Member Count */
  member_count: number;
}

/** GroupDetailSchema */
export interface GroupDetailSchema {
  /** Uuid */
  uuid: string;
  /** Name */
  name: string;
  /** Description */
  description: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Member Count */
  member_count: number;
  /** Members */
  members: GroupMemberSchema[];
}

/** GroupListResponse */
export interface GroupListResponse {
  /** Objects */
  objects: GroupBaseSchema[];
}

/** GroupMemberSchema */
export interface GroupMemberSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HostSchema */
export interface HostSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** InvitationSchema */
export interface InvitationSchema {
  participant: ParticipantSchema;
  /** Status */
  status: string;
}

/** LessonBaseSchema */
export interface LessonBaseSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Order */
  order: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Course Uuid */
  course_uuid: string;
  /** Course Title */
  course_title: string;
  /**
   * Is Complete
   * @default false
   */
  is_complete?: boolean;
}

/** LessonDetailSchema */
export interface LessonDetailSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Order */
  order: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Course Uuid */
  course_uuid: string;
  /** Course Title */
  course_title: string;
  /**
   * Is Complete
   * @default false
   */
  is_complete?: boolean;
  /** Content */
  content: string;
}

/** MeetingBaseSchema */
export interface MeetingBaseSchema {
  /** Uuid */
  uuid: string;
  host: HostSchema;
  /** Title */
  title: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Room Name */
  room_name: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Is Joinable */
  is_joinable: boolean;
  /** Group Uuid */
  group_uuid: string | null;
  /** Group Name */
  group_name: string | null;
  /** Context Uuid */
  context_uuid: string;
  meeting_type: MeetingTypeEnum;
  /** Custom Url */
  custom_url?: string | null;
}

/** MeetingListSchema */
export interface MeetingListSchema {
  /** Objects */
  objects: MeetingBaseSchema[];
}

/** MeetingSchema */
export interface MeetingSchema {
  /** Uuid */
  uuid: string;
  host: HostSchema;
  /** Title */
  title: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Room Name */
  room_name: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Is Joinable */
  is_joinable: boolean;
  /** Group Uuid */
  group_uuid: string | null;
  /** Group Name */
  group_name: string | null;
  /** Context Uuid */
  context_uuid: string;
  meeting_type: MeetingTypeEnum;
  /** Custom Url */
  custom_url?: string | null;
  /** Description */
  description: string;
  /** Invitations */
  invitations: InvitationSchema[];
  /** Jitsi Jwt */
  jitsi_jwt?: string | null;
  /**
   * Domain
   * @default "jitsi.fizikasasonjom.com"
   */
  domain?: string;
}

/** MeetingTypeEnum */
export enum MeetingTypeEnum {
  Jitsi = "jitsi",
  YoutubeLive = "youtube_live",
}

/** NavEntry */
export interface NavEntryInput {
  /** Uuid */
  uuid: string;
  /** Label */
  label: string;
  /** Url */
  url: string | null;
  /** Order */
  order: number;
  /** Parent Uuid */
  parent_uuid: string | null;
  /** Page Uuid */
  page_uuid: string | null;
  /** Icon */
  icon: string | null;
  /**
   * Children
   * @default []
   */
  children?: NavEntryInput[];
}

/** NavEntry */
export interface NavEntryOutput {
  /** Uuid */
  uuid: string;
  /** Label */
  label: string;
  /** Url */
  url: string | null;
  /** Order */
  order: number;
  /** Parent Uuid */
  parent_uuid: string | null;
  /** Page Uuid */
  page_uuid: string | null;
  /** Icon */
  icon: string | null;
  /**
   * Children
   * @default []
   */
  children?: NavEntryOutput[];
}

/** NavigationOutletResponse */
export interface NavigationOutletResponse {
  /** Objects */
  objects: NavEntryOutput[];
}

/** NavigationOutletUpdateRequest */
export interface NavigationOutletUpdateRequest {
  /** Naventries */
  naventries: NavEntryInput[];
}

/** PageCreateRequest */
export interface PageCreateRequest {
  /** Slug */
  slug?: string | null;
  /** Content */
  content: string;
  /** Title */
  title: string;
  /** Context Uuid */
  context_uuid?: string | null;
}

/** PageListResponse */
export interface PageListResponse {
  /** Objects */
  objects: PageOverviewResponse[];
}

/** PageOverviewResponse */
export interface PageOverviewResponse {
  /** Uuid */
  uuid: string;
  /** Slug */
  slug: string;
  /** Title */
  title: string;
  /**
   * Is Frontpage
   * @default false
   */
  is_frontpage?: boolean;
}

/** PageResponse */
export interface PageResponse {
  /** Uuid */
  uuid: string;
  /** Slug */
  slug: string;
  /** Title */
  title: string;
  /** Content */
  content: string;
  /**
   * Is Frontpage
   * @default false
   */
  is_frontpage?: boolean;
}

/** PageUpdateRequest */
export interface PageUpdateRequest {
  /** Slug */
  slug?: string | null;
  /** Title */
  title?: string | null;
  /** Content */
  content?: string | null;
  /** Is Frontpage */
  is_frontpage?: boolean | null;
}

/** ParticipantSchema */
export interface ParticipantSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** ProductBaseSchema */
export interface ProductBaseSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Price Cents */
  price_cents: number;
  /** Currency */
  currency: string;
  /** Subscription Duration Value */
  subscription_duration_value: number;
  subscription_duration_unit: SubscriptionDurationUnit;
  /** Active */
  active: boolean;
}

/** ProductDetailSchema */
export interface ProductDetailSchema {
  /** Uuid */
  uuid: string;
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Price Cents */
  price_cents: number;
  /** Currency */
  currency: string;
  /** Subscription Duration Value */
  subscription_duration_value: number;
  subscription_duration_unit: SubscriptionDurationUnit;
  /** Active */
  active: boolean;
  /** Groups */
  groups: GroupBaseSchema[];
}

/** ProductListSchema */
export interface ProductListSchema {
  /** Objects */
  objects: ProductBaseSchema[];
}

/**
 * PwdResetInfoSchema
 * Info contained in to token
 */
export interface PwdResetInfoSchema {
  /** User Uuid */
  user_uuid: string;
  /** Email */
  email: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/**
 * PwdResetRequestSchema
 * Request to reset password
 */
export interface PwdResetRequestSchema {
  /** Email */
  email: string;
}

/** RegisterRequest */
export interface RegisterRequest {
  /** Username */
  username: string;
  /** Email */
  email: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Password */
  password: string;
}

/** RegisterRequestOTP */
export interface RegisterRequestOTP {
  /** Email */
  email: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** RegisterTokenInfoSchema */
export interface RegisterTokenInfoSchema {
  /** Email */
  email: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** RegisterWithEmailRequest */
export interface RegisterWithEmailRequest {
  /**
   * Email
   * @format email
   */
  email: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
}

/** RegistrationStatusEnum */
export enum RegistrationStatusEnum {
  Registered = "registered",
  Unregistered = "unregistered",
  Pending = "pending",
}

/** RoleResponse */
export interface RoleResponse {
  /** Uuid */
  uuid: string;
  /** Description */
  description: string;
}

/** SubscriptionBaseSchema */
export interface SubscriptionBaseSchema {
  /** Uuid */
  uuid: string;
  /** Product Uuid */
  product_uuid: string;
  /**
   * Valid Until
   * @format date-time
   */
  valid_until: string;
  /**
   * Started On
   * @format date-time
   */
  started_on: string;
  /** Joined Group Uuid */
  joined_group_uuid: string | null;
  /** Joined Group Name */
  joined_group_name: string | null;
  /** Product Title */
  product_title: string;
  status: SubscriptionStatusEnum;
  user: UserBaseSchema;
}

/** SubscriptionDurationUnit */
export enum SubscriptionDurationUnit {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

/** SubscriptionListSchema */
export interface SubscriptionListSchema {
  /** Objects */
  objects: SubscriptionBaseSchema[];
}

/** SubscriptionStatusEnum */
export enum SubscriptionStatusEnum {
  Active = "active",
  Expired = "expired",
}

/** TokenSchema */
export interface TokenSchema {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
}

/** UpdateAcademyUserRequest */
export interface UpdateAcademyUserRequest {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Email */
  email: string;
  /** Field Values */
  field_values: UpdateUserFieldValueRequest[];
  /** Tag Uuids */
  tag_uuids: string[];
  /** Parent Uuid */
  parent_uuid: string | null;
}

/** UpdateCourseLesson */
export interface UpdateCourseLesson {
  /** Uuid */
  uuid?: string | null;
  /** Title */
  title: string;
  /** Order */
  order: number;
}

/** UpdateCourseRequest */
export interface UpdateCourseRequest {
  /** Title */
  title?: string | null;
  /** Description */
  description?: string | null;
  /** Lessons */
  lessons?: UpdateCourseLesson[] | null;
}

/** UpdateGroupRequest */
export interface UpdateGroupRequest {
  /** Name */
  name?: string | null;
  /** Description */
  description?: string | null;
  /** Member Uuids */
  member_uuids?: string[] | null;
}

/** UpdateLessonRequest */
export interface UpdateLessonRequest {
  /** Title */
  title: string;
  /** Content */
  content: string;
  /** Order */
  order: number;
}

/** UpdateMeetingRequest */
export interface UpdateMeetingRequest {
  /** Title */
  title: string;
  /**
   * Start Time
   * @format date-time
   */
  start_time: string;
  /**
   * End Time
   * @format date-time
   */
  end_time: string;
  /** Description */
  description: string;
  /** Participant Uuids */
  participant_uuids: string[];
  /** Custom Url */
  custom_url?: string | null;
  /** @default "jitsi" */
  meeting_type?: MeetingTypeEnum;
  /** Host Uuid */
  host_uuid: string;
}

/**
 * UpdatePasswordRequestSchema
 * Request to update password
 */
export interface UpdatePasswordRequestSchema {
  /** Password */
  password: string;
}

/** UpdateProductRequest */
export interface UpdateProductRequest {
  /** Title */
  title: string;
  /** Description */
  description: string;
  /** Price Cents */
  price_cents: number;
  /** Currency */
  currency: string;
  /** Subscription Duration Value */
  subscription_duration_value: number;
  subscription_duration_unit: SubscriptionDurationUnit;
  /** Active */
  active: boolean;
  /** Group Uuids */
  group_uuids: string[];
}

/** UpdateSubscriptionRequest */
export interface UpdateSubscriptionRequest {
  /** Product Uuid */
  product_uuid: string;
  status: SubscriptionStatusEnum;
  /** Joined Group Uuid */
  joined_group_uuid: string | null;
  /**
   * Valid Until
   * @format date-time
   */
  valid_until: string;
  /**
   * Started On
   * @format date-time
   */
  started_on: string;
}

/** UpdateUserFieldRequest */
export interface UpdateUserFieldRequest {
  /** Label */
  label: string;
  /** Display Order */
  display_order: number;
  /** Display In List */
  display_in_list: boolean;
}

/** UpdateUserFieldValueRequest */
export interface UpdateUserFieldValueRequest {
  /** Field Uuid */
  field_uuid: string;
  /** String Value */
  string_value: string | null;
  /** Integer Value */
  integer_value: number | null;
  /** Boolean Value */
  boolean_value: boolean | null;
  /** Datetime Value */
  datetime_value: string | null;
  /** Text Value */
  text_value: string | null;
  /** Float Value */
  float_value: number | null;
}

/** UpdateUserFieldsRequest */
export interface UpdateUserFieldsRequest {
  /** Fields */
  fields: UserFieldSchema[];
}

/** UpdateUserTagRequest */
export interface UpdateUserTagRequest {
  /** Label */
  label: string;
  /** Color */
  color: string;
}

/** UserBaseSchema */
export interface UserBaseSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Newsletter Consent */
  newsletter_consent: boolean;
  /** Email */
  email: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Field Values */
  field_values: UserFieldValueSchema[];
  /** Tag Uuids */
  tag_uuids: string[];
  registration_status: RegistrationStatusEnum;
}

/** UserFieldListSchema */
export interface UserFieldListSchema {
  /** Objects */
  objects: UserFieldSchema[];
}

/** UserFieldSchema */
export interface UserFieldSchema {
  /** Uuid */
  uuid: string;
  field_type: UserFieldTypeEnum;
  /** Label */
  label: string;
  /** Display Order */
  display_order: number;
  /** Display In List */
  display_in_list: boolean;
}

/** UserFieldTypeEnum */
export enum UserFieldTypeEnum {
  String = "string",
  Text = "text",
  Integer = "integer",
  Boolean = "boolean",
  Datetime = "datetime",
  Float = "float",
}

/** UserFieldValueSchema */
export interface UserFieldValueSchema {
  /** Field Uuid */
  field_uuid: string;
  /** String Value */
  string_value: string | null;
  /** Integer Value */
  integer_value: number | null;
  /** Boolean Value */
  boolean_value: boolean | null;
  /** Datetime Value */
  datetime_value: string | null;
  /** Text Value */
  text_value: string | null;
  /** Float Value */
  float_value: number | null;
}

/** UserListResponse */
export interface UserListResponse {
  /** Objects */
  objects: UserBaseSchema[];
}

/** UserSchema */
export interface UserSchema {
  /** Uuid */
  uuid: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Newsletter Consent */
  newsletter_consent: boolean;
  /** Email */
  email: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Field Values */
  field_values: UserFieldValueSchema[];
  /** Tag Uuids */
  tag_uuids: string[];
  registration_status: RegistrationStatusEnum;
  /** Roles */
  roles: RoleResponse[];
}

/** UserTagListSchema */
export interface UserTagListSchema {
  /** Objects */
  objects: UserTagSchema[];
}

/** UserTagSchema */
export interface UserTagSchema {
  /** Uuid */
  uuid: string;
  /** Label */
  label: string;
  /** Color */
  color: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}
